// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Dialog.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Dialog.tsx");
  import.meta.hot.lastModified = "1728642254858.51";
}
// REMIX HMR END

"use client";
import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "~/utils/cn";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ShortcutKey } from "./ShortcutKey";
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = ({
  children,
  ...props
}) => <DialogPrimitive.Portal {...props}>
    <div className="fixed inset-0 z-50 flex items-start justify-center sm:items-center">
      {children}
    </div>
  </DialogPrimitive.Portal>;
_c = DialogPortal;
DialogPortal.displayName = DialogPrimitive.Portal.displayName;
const DialogOverlay = React.forwardRef(_c2 = ({
  className,
  ...props
}, ref) => <DialogPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-background-dimmed/90 backdrop-blur-sm transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in", className)} {...props} />);
_c3 = DialogOverlay;
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef(_c4 = ({
  className,
  children,
  ...props
}, ref) => <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content ref={ref} className={cn("fixed z-50 grid w-full gap-4 rounded-b-lg border bg-background-dimmed px-4 pb-4 pt-2.5 shadow-lg animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 sm:max-w-lg sm:rounded-lg sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0", className)} {...props}>
      <hr className="absolute left-0 top-11 w-full" />
      {children}
      <DialogPrimitive.Close className="data-[state=open]:bg-accent data-[state=open]:text-muted-foreground absolute right-3 top-2 rounded-sm py-1 pr-1 opacity-70 transition-opacity focus-custom hover:opacity-100 disabled:pointer-events-none">
        <div className="flex gap-x-2">
          <ShortcutKey shortcut={{
          windows: {
            key: "esc"
          },
          mac: {
            key: "esc"
          }
        }} variant={"small"} />
          <XMarkIcon className="h-5 w-5" />
          <span className="sr-only">Close</span>
        </div>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>);
_c5 = DialogContent;
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({
  className,
  ...props
}) => <div className={cn("flex flex-col text-left font-medium text-text-bright", className)} {...props} />;
_c6 = DialogHeader;
DialogHeader.displayName = "DialogHeader";
const DialogFooter = ({
  className,
  ...props
}) => <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-between sm:space-x-2", className)} {...props} />;
_c7 = DialogFooter;
DialogFooter.displayName = "DialogFooter";
const DialogTitle = React.forwardRef(_c8 = ({
  className,
  ...props
}, ref) => <DialogPrimitive.Title ref={ref} className={cn("text-base font-normal leading-none tracking-tight text-text-bright", className)} {...props} />);
_c9 = DialogTitle;
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef(_c10 = ({
  className,
  ...props
}, ref) => <DialogPrimitive.Description ref={ref} className={cn("text-muted-foreground pt-4 text-sm", className)} {...props} />);
_c11 = DialogDescription;
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11;
$RefreshReg$(_c, "DialogPortal");
$RefreshReg$(_c2, "DialogOverlay$React.forwardRef");
$RefreshReg$(_c3, "DialogOverlay");
$RefreshReg$(_c4, "DialogContent$React.forwardRef");
$RefreshReg$(_c5, "DialogContent");
$RefreshReg$(_c6, "DialogHeader");
$RefreshReg$(_c7, "DialogFooter");
$RefreshReg$(_c8, "DialogTitle$React.forwardRef");
$RefreshReg$(_c9, "DialogTitle");
$RefreshReg$(_c10, "DialogDescription$React.forwardRef");
$RefreshReg$(_c11, "DialogDescription");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;